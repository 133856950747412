import React from "react";
//Import img
import Logo from "../img/logo.png";
import LogoText from "../img/logoalattszoveg.svg";

//Import icons
import FacebookIcon from "../img/facebook.svg";
import MailIcon from "../img/mail.svg";
import InstagramIcon from "../img/insta.svg";

//Import Styles
import styled from "styled-components";

//Import animations
import { motion } from "framer-motion";

export default function Header() {
  return (
    <LogoContainer>
      <motion.div
        variants={HeaderAnimation}
        initial='hidden'
        animate='show'
        className='logobox-container'>
        <a href='/'>
          <img className='logo' src={Logo} alt='logo' />
          <img
            src={LogoText}
            alt='mozgasban a tsztasag'
            className='logo-text'
          />
        </a>
      </motion.div>
      <motion.div
        variants={HeaderAnimation}
        initial='hidden'
        animate='show'
        className='social-container'>
        <a href='https://www.facebook.com/cleanmotion.hu'>
          <img src={FacebookIcon} alt='facebook' />
        </a>
        <a href='https://www.instagram.com/cleanmotion.hu/'>
          <img src={InstagramIcon} alt='InstagramIcon' />
        </a>
        <a href='mailto:info@cleanmotion.hu'>
          <img src={MailIcon} alt='MailIcon' />
        </a>
      </motion.div>
    </LogoContainer>
  );
}

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 2rem;
  .logobox-container {
    position: relative;
    .logo {
      width: 45%;
    }
    .logo-text {
      position: absolute;
      bottom: -60%;
      right: 30%;
      width: 45%;
    }
  }
  .social-container {
    display: flex;
    a {
      margin: 0 0.2rem;
      img {
        width: 100%;
      }
    }
  }
  @media only screen and (max-width: 992px) {
    width: 95%;
    margin-top: -1rem !important;
    .logo {
      width: 75% !important;
      margin-top: -1rem !important;
    }
    .logo-text {
      width: 56% !important;
    }
    .social-container {
      flex-direction: column;
      a {
        margin: 0 0.2rem;
        img {
          width: 130%;
        }
      }
    }
  }
  @media screen and (max-width: 778px) {
    .logo {
      width: 95% !important;
    }
    .logo-text {
      width: 85% !important;
      bottom: -76% !important;
      right: 5% !important;
    }
    .social-container {
      flex-direction: column;
      a {
        margin: 0 0.2rem;
        img {
          width: 350% !important;
        }
      }
    }
  }
`;

const HeaderAnimation = {
  hidden: {
    opacity: 0,
    y: -100,
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1.7,
    },
  },
};
