import React from 'react'
import ReactPlayer from "react-player/lazy"
export default function Video({video}) {
    return (
        <ReactPlayer 
            url={video}
            width='100%'
            height='100%'
            controls='true'
            />
    )
}