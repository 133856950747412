import React from 'react'
//Import Styles
import styled from 'styled-components'


export default function DetailHeader({state}) {
  const State = state
    return (
        <Header>
          <img src={State.SectionHeaderImg} alt={State.SectionHeaderImg}/>  
          <h1>{State.SectionHeaderTitle}</h1>
        </Header>
    )
}
const Header = styled.div`
  display: flex;
  flex-direction:column;
  align-items:center;
  margin: 5rem 0;
  img{
    width:100%
  };
  h1{
    font-size: 1.5rem;
    color: #00A0E4;
    margin-top: -2.5rem;
    margin-bottom: 3rem;
  }
  @media only screen and (max-width: 992px) {
       margin: 3rem 0!important;
      h1{
        font-size: 1rem;
        margin-top: 0;
      }
    }
    @media screen and (max-width: 778px){
        padding: 0 !important;
    }
`;