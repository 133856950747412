import React from 'react'
import styled from 'styled-components'
import DetailHeader from '../components/DetailHeader'
import Section from '../components/Section'
import ListedSection from '../components/SectionListed'

//import components
import Video from '../components/video';
//Import animations
import {motion} from 'framer-motion'


export default function DetailsPage({state}) {
    return (
        <StyledDetailsPage variants={HeaderAnimation} initial='hidden' animate='show'>
          <DetailHeader state={state} />
          {state.ListedSection1 ?  <ListedSection state={state.ListedSection1}/>  : null}
          {state.VideoIS1 && <div className="video-player"><Video className='video-player' video={state.VideoIS1}/></div> }
          {state.VideoLS && <div className="video-player"><Video className='video-player' video={state.VideoLS}/></div> }
          {state.ListedSection2 ?  <ListedSection state={state.ListedSection2}/>  : null}
          {state.VideoIS2 && <div className="video-player"><Video className='video-player' video={state.VideoIS2}/></div> }
          {state.Section1 ?  <Section state={state.Section1}/> : null}
          {state.VideoIS3 && <div className="video-player"><Video className='video-player' video={state.VideoIS3}/></div> }
          {state.SectionText ?  <Section state={state}/> : null}
          {state.VideoLS1 && <div className="video-player"><Video className='video-player' video={state.VideoLS1}/></div> }
          {state.ListedSectionText ?  <ListedSection state={state}/>  : null}
          {state.Section2 ?  <Section state={state.Section2}/> : null}
          {state.VideoIS4 && <div className="video-player"><Video className='video-player' video={state.VideoIS4}/></div> }
          {state.Section3 ?  <Section state={state.Section3}/> : null}
          {state.Section4 ?  <Section state={state.Section4}/> : null}
          {state.Section5 ?  <Section state={state.Section5}/> : null}
          {state.VideoLG && <div className="video-player"><Video className='video-player' video={state.VideoLG}/></div> }
          {state.VideoIO && <div className="video-player"><Video className='video-player' video={state.VideoIO}/></div> }
          {state.VideoLO && <div className="video-player"><Video className='video-player' video={state.VideoLO}/></div> }
          {state.VideoIG && <div className="video-player"><Video className='video-player' video={state.VideoIG}/></div> }
        </StyledDetailsPage>
    )
}
const StyledDetailsPage = styled(motion.div)`
  margin: 0 auto;
  width: 65%;
  .video-player{
    height: 75vh !important;
  }
  @media only screen and (max-width: 992px) {
      width:95% !important;

    }
`;
const HeaderAnimation = {
  hidden: {
      opacity: 0,
      y: -100
  },
  show:{
      opacity: 1,
      y:0,
      transition: {
          duration:1.7,
      }
  }
}
