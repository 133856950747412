import React from "react";
import { Link } from "react-router-dom";
import "./form.css";
import Kalk from "../img/kalkgomb.svg";
import Tel from "../img/tel1.svg";
import Cost from "../img/cost.svg";
import Quest from "../img/kerdes.svg";

function form() {
  return (
    <div className='form-container'>
      <div className='calc'>
        <img className='szoveg' src={Cost} alt='' />
        <Link to='/kalkulator'>
          <img src={Kalk} alt='' />
        </Link>
      </div>
      <div className='question'>
        <img className='szoveg' src={Quest} alt='' />
        <a href='tel:+36302349108'>
          {" "}
          <img className='tel' src={Tel} alt='' />{" "}
        </a>
      </div>
    </div>
  );
}

export default form;
