import React, { useState, useEffect } from "react";
import styled from "styled-components";
import getDistance from "geolib/es/getDistance";
import CostPic from "../img/cost.png";
import Data from "../data.json";
//Import animations
import { motion } from "framer-motion";

export default function Calculator() {
  const [latitude, setlatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [distance, setDistance] = useState();
  const [geolocation, setGetlocation] = useState();
  const [m2, setm2] = useState(0);
  const [final, setfinal] = useState();
  const [final2, setfinal2] = useState();
  const [cost, setCost] = useState();
  const [locationa, setLocationa] = useState();
  const [Search, setSearch] = useState("");
  const [SearchedLocation, setSearchedLocation] = useState("");
  const [ShowO, setShow] = useState(true);
  const [Show1, setShow1] = useState(false);
  const [name, setName] = useState();

  //const allLocation = locationa.map(t => t.Helységnév)

  // navigator.geolocation.getCurrentPosition((position) => {
  //  setlatitude(position.coords.latitude)
  //  setLongitude(position.coords.longitude)
  // })
  //console.log(temp)
  //console.log(locationa.Helységnév)

  //function RemoveAccents(s) {
  //  var i = 'ÀÁÂÃÄÅàáâãäåÒÓÔÕÕÖŐòóôõöőÈÉÊËèéêëðÇçÐÌÍÎÏìíîïÙÚÛÜŰùúûüűÑñŠšŸÿýŽž'.split('');
  // var o = 'AAAAAAaaaaaaOOOOOOOooooooEEEEeeeeeCcDIIIIiiiiUUUUUuuuuuNnSsYyyZz'.split('');
  //  var map = {};
  //  i.forEach((i, idx) => map[i] = o[idx]);
  //   return s.replace(/[^A-Za-z0-9]/g, function(ch) { return map[ch] || ch; })
  //  }

  const convert = (ew) => {
    const s = ew.split(":");
    const a = s[1];
    const q = a.split(".");
    const perc = q[0];
    const mp = q[1];
    const p = s[0];
    const fok = (perc + mp / 60) / 60 + "";
    const qb = fok.split(".");
    return p + "." + qb[0] + qb[1];
  };

  // const calcCost = () => {
  //   if (m2 <= 20) {
  //     setCost(6000);
  //   } else if (m2 >= 20 && m2 <= 40) {
  //     setCost(12000);
  //   } else if (m2 >= 40 && m2 <= 60) {
  //     setCost(18000);
  //   } else if (m2 >= 60 && m2 <= 80) {
  //     setCost(24000);
  //   } else if (m2 >= 80 && m2 <= 100) {
  //     setCost(30000);
  //   } else if (m2 >= 100 && m2 <= 150) {
  //     setCost(37500);
  //   } else if (m2 >= 150 && m2 <= 200) {
  //     setCost(46000);
  //   } else if (m2 >= 201 && m2 <= 499) {
  //     setCost(180 * m2);
  //   } else if (m2 >= 500 && m2 <= 999) {
  //     setCost(150 * m2);
  //     console.log(cost);
  //     console.log(m2);
  //   } else {
  //     setCost(100 * m2);
  //   }
  // };

  const rangeHandler = (e) => {
    if (e.target.value) {
      console.log(e.target.value);
      setm2(e.target.value * 1);
      console.log(m2);
      if (e.target.value <= 20) {
        setCost(6000);
      } else if (e.target.value >= 20 && e.target.value <= 40) {
        setCost(12000);
      } else if (e.target.value >= 40 && e.target.value <= 60) {
        setCost(18000);
      } else if (e.target.value >= 60 && e.target.value <= 80) {
        setCost(24000);
      } else if (e.target.value >= 80 && e.target.value <= 100) {
        setCost(30000);
      } else if (e.target.value >= 100 && e.target.value <= 150) {
        setCost(37500);
      } else if (e.target.value >= 150 && e.target.value <= 200) {
        setCost(46000);
      } else if (e.target.value >= 201 && e.target.value <= 499) {
        setCost(180 * e.target.value);
      } else if (e.target.value >= 500 && e.target.value <= 999) {
        setCost(150 * e.target.value);
        console.log(cost);
        console.log(e.target.value);
      } else {
        setCost(100 * e.target.value);
      }
    }
  };

  const calcFinal = (e) => {
    const vg = distance * 2 + cost;
    setfinal(vg.toFixed(0));
  };

  const searchHandler = (e) => {
    setShow(true);
    setShow1(false);
    setfinal(false);
    setm2(false);
    setSearch(e.target.value);
    if (Search !== "") {
      const currentLocation = Data.filter((t) => t.Helysegnev.includes(Search));
      setSearchedLocation(currentLocation);
    }
  };
  const clickHandle = (e) => {
    setlatitude(e.target.lang);
    setLongitude(e.target.id);
    setName(e.target.innerText);
    setShow(false);
    setShow1(true);
  };
  const showHandle = (e) => {
    setShow(true);
    setShow1(false);
  };
  const StyledOption = styled.p`
    display: ${ShowO ? "block" : "none"};
    font-size: 1.2rem;
    cursor: pointer;
  `;
  const StyledHide = styled.div`
    display: ${Show1 ? "flex" : "block"};
    align-items: center;
    margin-top: 1rem;
    height: ${ShowO ? "35vh" : "15vh"};
    overflow: scroll;
  `;
  useEffect(() => {
    if (Search !== "") {
      setLocationa(SearchedLocation);
    } else {
      setLocationa(Data);
    }
  }, [Search]);
  useEffect(() => {
    setfinal2(final);
  }, [final]);

  useEffect(() => {
    if (latitude !== undefined) {
      const lati = convert(latitude);
      const longi = convert(longitude);
      const el = getDistance(
        { latitude: lati, longitude: longi },
        { latitude: "47.7390851", longitude: "18.1267006" }
      );
      setGetlocation(el);
      if (el <= 15000) {
        setDistance(0);
      } else if (el >= 15000 && el <= 25000) {
        setDistance(1500);
      } else if (el >= 25000 && el <= 35000) {
        setDistance(3000);
      } else {
        setDistance(40 * (el / 1000));
      }
    } else return;
  }, [name]);
  return (
    <StyledCalculator
      variants={HeaderAnimation}
      initial='hidden'
      animate='show'>
      <div className='input-container'>
        <h1>Adja meg a területet:</h1>
        <input type='text' onChange={searchHandler} />
        <StyledHide>
          <div className='option-container'>
            {Show1 ? <StyledP onClick={showHandle}>{name}</StyledP> : null}
            {locationa
              ? locationa.map((t) => (
                  <StyledOption
                    lang={t.Eszakiszelesseg}
                    id={t.Keletihosszusag}
                    onClick={clickHandle}>
                    {t.Helysegnev}
                  </StyledOption>
                ))
              : null}
          </div>
        </StyledHide>
      </div>

      <h1>Adja meg a terület paramétereit:</h1>
      <input
        onChange={rangeHandler}
        type='range'
        min='0'
        max='1000'
        step='1'
        defaultValue='10'
      />

      <h1>{m2}m²</h1>
      <button onClick={calcFinal}>Számol</button>
      {final2 && <h2>Összesen: {final2}Ft + ÁFA</h2>}
      <img src={CostPic} alt='' />
    </StyledCalculator>
  );
}

const StyledCalculator = styled(motion.div)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-top: 5rem;
  @media only screen and (max-width: 992px) {
    img {
      width: 100% !important;
      margin: 0 auto;
    }
  }
  input {
    font-size: 1rem;
    padding: 0.5rem;
  }
  img {
    width: 50%;
    margin: 5rem auto;
  }
  button {
    padding: 0.6rem;
    margin: 2rem;
    font-size: 1rem;
    width: 6rem;
    background-color: #84b938;
    border: none;
    border-radius: 15px;
  }
`;
const StyledP = styled.p`
  font-size: 2rem;
  font-weight: 800;
  margin: 0 0 0 2rem;
  cursor: pointer;
`;
const HeaderAnimation = {
  hidden: {
    opacity: 0,
    y: -100,
  },
  show: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 1.7,
    },
  },
};
