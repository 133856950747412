import React from 'react'
//Import Styles
import styled from 'styled-components'

//Import animations
import {motion} from 'framer-motion'


export default function Link({leaf, text, url}) {
    return (
        <StyledLink variants={LeafAnimaton} initial='hidden' animate='show' href={url} >
            <img src={leaf} alt={text}/>
            <motion.p variants={LeafAnimaton} initial='hidden' animate='show'>{text}</motion.p>
        </StyledLink>
    )
};

const StyledLink = styled(motion.a)`
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    text-decoration: none;
    text-transform: uppercase;
    color: #00A0E4;
    img{
        width: 25%;
        margin: 3rem 0 1rem 0;
    }
    p{
        font-size: 1rem;
    }
    @media only screen and (max-width: 992px) {
        img{
            width:50%;
        }
        p{
            font-size:.7rem;
        }

    }
    @media screen and (max-width: 778px){
        padding: 0 !important;
    }
`;

const LeafAnimaton = {
    hidden: {
        opacity: 0,
        y: -50,
        
    },
    show: {
        y: 0,
        opacity: 1,
        transition: {
            delay: .15,
            duration: 2,
        }
    }
}