import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import Link from "../components/Link";
//Import Imgs
import Ozonviz from "../img/ozonosviz.png";
import uvc from "../img/uvc.png";
import moso from "../img/moso.png";
import ozongenerator from "../img/ozongenerator.png";
import goztisztito from "../img/goztisztito.png";
import hidegkod from "../img/hidegkod.png";

//Import Styles
import styled from "styled-components";

export default function Links() {
  const hisory = useHistory();
  const url = hisory.location.pathname;
  const [links] = useState([
    {
      leaf: Ozonviz,
      text: "ÓZONOS VÍZ",
      url: url === "/lakossagi" ? "#/lakossagi/ozonosviz" : "#/ipari/ozonosviz",
      id: 1,
    },
    {
      leaf: uvc,
      text: "UVC",
      url: url === "/lakossagi" ? "#/lakossagi/uvc" : "#/ipari/uvc",
      id: 2,
    },
    {
      leaf: moso,
      text: "MAGASNYOMÁSÚ MOSÓ-SÚROLÓ ",
      url: url === "/lakossagi" ? "#/lakossagi/surolo" : "#/ipari/surolo",
      id: 3,
    },
    {
      leaf: ozongenerator,
      text: "ÓZONGENERÁTOR",
      url:
        url === "/lakossagi"
          ? "#/lakossagi/ozongenerator"
          : "#/ipari/ozongenerator",
      id: 4,
    },
    {
      leaf: goztisztito,
      text: "GÕZTISZTÍTÓ",
      url:
        url === "/lakossagi"
          ? "#/lakossagi/goztisztito"
          : "#/ipari/goztisztito",
      id: 5,
    },
    {
      leaf: hidegkod,
      text: "HIDEGKÖD",
      url: url === "/lakossagi" ? "#/lakossagi/hidegkod" : "#/ipari/hidegkod",
      id: 6,
    },
  ]);

  return (
    <StyledLinks>
      {links.map((link) => (
        <Link leaf={link.leaf} text={link.text} url={link.url} key={link.id} />
      ))}
    </StyledLinks>
  );
}

const StyledLinks = styled.div`
  padding: 0 13rem;
  display: flex;
  flex-wrap: wrap;
  @media only screen and (max-width: 992px) {
    padding: 0 !important;
  }
  @media screen and (max-width: 778px) {
    padding: 0 !important;
  }
`;
