import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body{
    padding: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    color: #5b5b5b;
    max-width: 99vw;
    font-size: .9rem;
}
.container{
    height: 65vh;
}
`;

export default GlobalStyle;
