import React from 'react'


//Import Styles
import styled from 'styled-components'


export default function Section({state}) {
    const StyledSection = styled.div`
display:flex;
justify-content:center;
flex-direction:column;
align-items: center;
margin: 3rem 0;
h4{
    margin-bottom: 1rem;
    color: #585858;
}
.section-container{
    display:flex;
    flex-direction: ${state.rRow ? 'row-reverse' : 'row'};
    align-items:center;
    p{
        line-height: 170%;
        color: #5b5b5b;
    }
    img{
        width: 15vw;
        height: fit-content;
    }
}
@media only screen and (max-width: 992px) {
        

    }
    @media screen and (max-width: 778px){
        .section-container{
            flex-direction: column-reverse;
            img{
                width: 100%;
            }
        }
    }
`;

    return (
        <StyledSection>
        <h4>{state.SectionTitle}</h4>
        <div className="section-container">
            <img src={state.SectionImg} alt=""/>  
            <p>{state.SectionText}</p>
        </div>
          
        </StyledSection>
    )
}



