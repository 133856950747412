import React, {useState} from 'react'

import { Route, useHistory} from 'react-router-dom'

//import GlobalStyle
import GloalStyle from './components/GlobalStyles'

//Import Pages
import MainPage from './pages/Mainpage'
import Links from './pages/Links'
import DetailsPage from './pages/DetailsPage'

//import components
import Header from './components/Header'
import Form from './components/Form'
import Kalkulator from './components/Calcuator'

//import Data
import {ipar} from './Data'
import {lakossagi} from './Data'


function App() {
  const hisory = useHistory();
  const url = hisory.location.pathname;
  const [ipari] = useState(ipar);
  const [lakossag] = useState(lakossagi)
  return (
  <div className='App'>
    <GloalStyle />
    <Header />
    <Route exact path='/kalkulator'>
      <Kalkulator />
    </Route>
    <Route exact path='/' >
      <MainPage />
    </Route>
    <Route exact path='/lakossagi' >
      <Links />
    </Route>
    <Route exact path='/ipari' >
      <Links />
    </Route>
    <Route exact path={ipari.ozonviz.url} >
      <DetailsPage state={ipari.ozonviz}/>
    </Route>
    <Route exact path={ipari.uvc.url} >
      <DetailsPage state={ipari.uvc}/>
    </Route>
    <Route exact path={ipari.goztisztito.url} >
      <DetailsPage state={ipari.goztisztito}/>
    </Route>
    <Route exact path={ipari.ozongenerator.url} >
      <DetailsPage state={ipari.ozongenerator}/>
    </Route>
    <Route exact path={ipari.surolo.url} >
      <DetailsPage state={ipari.surolo}/>
    </Route>
    <Route exact path={ipari.iparihidegkod.url} >
      <DetailsPage state={ipari.iparihidegkod}/>
    </Route >
    <Route exact path={lakossag.ozonviz.url} >
      <DetailsPage state={lakossag.ozonviz}/>
    </Route>
    <Route exact path={lakossag.uvc.url} >
      <DetailsPage state={lakossag.uvc}/>
    </Route>
    <Route exact path={lakossag.goztisztito.url} >
      <DetailsPage state={lakossag.goztisztito}/>
    </Route>
    <Route exact path={lakossag.ozongenerator.url} >
      <DetailsPage state={lakossag.ozongenerator}/>
    </Route>
    <Route exact path={lakossag.surolo.url} >
      <DetailsPage state={lakossag.surolo}/>
    </Route>
    <Route exact path={lakossag.lakossagihidegkod.url} >
      <DetailsPage state={lakossag.lakossagihidegkod}/>
    </Route >
    {url === '/kalkulator' ? null: <Form /> }
  </div>
  );
}

export default App;
