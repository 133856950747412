import React from "react";

//Import Styles
import styled from "styled-components";
//import img
import LeafLakossagi from "../img/leaf2.png";
import LeafIpari from "../img/leaf1.png";

//Import animations
import { motion } from "framer-motion";

export default function Mainpage() {
  return (
    <StyledContainer className='container'>
      <motion.h1 variants={TitleAnimation} initial='hidden' animate='show'>
        gyors -{" "}
        <motion.span variants={TitleAnimationH} initial='hidden' animate='show'>
          hatékony -
        </motion.span>{" "}
        <motion.span variants={TitleAnimationK} initial='hidden' animate='show'>
          környezetbarát
        </motion.span>
      </motion.h1>
      <LeafContainer>
        <motion.div
          variants={LeafIpariAnimaton}
          initial='hidden'
          animate='show'
          className='lakossagi-link'>
          <a href='#/lakossagi'>
            <img src={LeafLakossagi} alt='' />
            <motion.h2
              variants={LeafTextAnimation}
              initial='hidden'
              animate='show'>
              Lakossági <br />{" "}
              <motion.span
                variants={LeafTextMiniAnimation}
                initial='hidden'
                animate='show'>
                takarítás, <br />
              </motion.span>{" "}
              <motion.span
                variants={LeafTextMiniAnimation}
                initial='hidden'
                animate='show'>
                fertőtlenítés
              </motion.span>{" "}
            </motion.h2>
          </a>
        </motion.div>
        <motion.div
          variants={LeafLakossagiAnimaton}
          initial='hidden'
          animate='show'
          className='ipari-link'>
          <a href='#/ipari'>
            <img src={LeafIpari} alt='' />
            <motion.h2
              variants={LeafTextIAnimation}
              initial='hidden'
              animate='show'>
              Ipari <br />{" "}
              <motion.span
                variants={LeafTextIMiniAnimation}
                initial='hidden'
                animate='show'>
                takarítás, <br />
              </motion.span>{" "}
              <motion.span
                variants={LeafTextMiniAnimation}
                initial='hidden'
                animate='show'>
                fertőtlenítés
              </motion.span>{" "}
            </motion.h2>
          </a>
        </motion.div>
      </LeafContainer>
    </StyledContainer>
  );
}

const StyledContainer = styled.div`
  /* margin-bottom: -10rem; */

  h1 {
    text-align: center;
    margin: 5rem auto -2rem auto;
    color: #66b338;
    text-transform: uppercase;
    font-weight: 900;
  }
  /* @media only screen and (max-width: 992px) {
    margin-bottom: -6rem;
  } */
`;

const LeafContainer = styled.div`
  margin: 2rem auto 150rem auto;
  display: flex;
  justify-content: center;
  max-width: 59vw !important;
  position: relative;
  font-family: "Poppins", sans-serif;

  .lakossagi-link {
    position: absolute;
    top: 0%;
    z-index: 2;
    width: 25%;
    a {
      h2 {
        position: absolute;
        bottom: 25%;
        right: 0%;
        font-size: 1.3rem;
        transform: rotate(75deg);
        transition: all 0.3s ease-in-out;
        text-decoration: none;
        color: #ececec;
        text-shadow: 5px 5px 15px #111111;
        span {
          padding-left: 1rem;
        }
      }
      h2:hover {
        margin-bottom: 0.5rem;
      }
      width: min-content;
      img {
        width: 100%;
      }
    }
  }
  .ipari-link {
    position: absolute;
    top: 0%;
    right: 38%;
    z-index: 1;
    width: 23%;
    a {
      h2 {
        position: absolute;
        bottom: 25%;
        right: 12%;
        font-size: 1.3rem;
        transform: translateX(-56%);
        text-decoration: none;
        color: #ececec;
        text-shadow: 5px 5px 15px #111111;
        transition: all 0.3s ease-in-out;
        &:hover {
          margin-bottom: 0.5rem;
        }
        span {
          padding-left: 1rem;
        }
      }
      img {
        width: 100%;
      }
    }
  }
  @media only screen and (max-width: 1100px) {
    .ipari-link {
      left: 30% !important;
      width: 28%;
    }
    .lakossagi-link {
      left: 33% !important;
      width: 30%;
    }
  }
  @media only screen and (max-width: 992px) {
    .ipari-link {
      left: 30% !important;
      width: 28%;
    }
    .lakossagi-link {
      left: 33% !important;
      width: 30%;
    }
  }
  @media only screen and (max-width: 920px) {
    .ipari-link {
      left: 30% !important;
      width: 35%;
    }
    .lakossagi-link {
      left: 33% !important;
      width: 36%;
    }
  }
  @media only screen and (max-width: 778px) {
    .ipari-link {
      top: 0;
      left: 30% !important;
      width: 38%;
    }
    .lakossagi-link {
      left: 33% !important;
      width: 40%;
    }
  }
  @media only screen and (max-width: 650px) {
    .ipari-link {
      top: 0;
      left: 30% !important;
      width: 45%;
      a {
        h2 {
          font-size: 0.95rem;
        }
      }
    }
    .lakossagi-link {
      left: 33% !important;
      width: 49%;
      a {
        h2 {
          font-size: 0.95rem;
        }
      }
    }
  }
  @media only screen and (max-width: 550px) {
    .ipari-link {
      left: 3% !important;
      width: 55%;
    }
    .lakossagi-link {
      left: 28% !important;
      width: 57%;
    }
  }
  /* @media screen and (max-width: 778px) {
    .ipari-link {
      left: -30% !important;
      a {
        img {
          width: 100%;
        }
        h2 {
          font-size: 1.2rem;
        }
      }
    }
    .lakossagi-link {
      right: -30% !important;
      a {
        img {
          width: 100%;
        }
        h2 {
          font-size: 1.2rem;
        }
      }
    }
  } */
`;

const LeafIpariAnimaton = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    y: 49,
    x: -140,
    rotate: -76,
    transition: {
      duration: 2.2,
    },
  },
};

const LeafLakossagiAnimaton = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    y: 25,
    x: 156,
    rotate: 76,
    transition: {
      duration: 2.2,
      staggerChildren: 6,
      when: "beforeChildren",
    },
  },
};

const LeafTextAnimation = {
  hidden: {
    opacity: 0,
    y: 0,
    x: 0,
    rotate: 74,
  },
  show: {
    rotate: 75.75,
    opacity: 1,
    y: -5,
    x: -5,
    transition: {
      delay: 2,
      duration: 0.8,
    },
  },
};
const LeafTextMiniAnimation = {
  hidden: {
    opacity: 0,
    y: 0,
    x: 0,
    rotate: 74.75,
  },
  show: {
    rotate: 75.75,
    opacity: 1,
    y: -10,
    x: -5,
    transition: {
      delay: 2.4,
      duration: 0.8,
    },
  },
};

const LeafTextIAnimation = {
  hidden: {
    opacity: 0,
    y: 0,
    x: 0,
    rotate: -75,
  },
  show: {
    rotate: -75.75,
    opacity: 1,
    y: -5,
    x: -13,
    transition: {
      delay: 2,
      duration: 0.8,
    },
  },
};

const LeafTextIMiniAnimation = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      delay: 2.4,
      duration: 0.8,
    },
  },
};
const TitleAnimation = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      delay: 3.2,
      duration: 1,
    },
  },
};
const TitleAnimationH = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      delay: 3.4,
      duration: 1.2,
    },
  },
};
const TitleAnimationK = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      delay: 3.6,
      duration: 1.2,
    },
  },
};
