import React from 'react'

//import components
import Video from './video';

//import imgs
import LittleLeaf from '../img/kislevel.png'

//Import Styles
import styled from 'styled-components'

export default function SectionListed({state}) {
    const StyledListedSection = styled.div`
display:flex;
justify-content:center;
flex-direction:column;
align-items: center;
margin: 4rem 0 ;
h4{
    margin: 3rem 0 1rem 0;
    color: #585858;
}
.listed-section-container{
    display:flex;
    flex-direction: ${state.rRow ? 'row-reverse' : 'row'};
    justify-content: space-around;
    margin: 0 0rem;
    align-items:center;
    width: 65vw;
}
.listed-section-list{
    width: 50%;
    margin: 0 auto;
}
.listed-section-img-container{
  width:50%;
  .video-container{
      width: 100%;
      height: 40vh;
      align-self: center;
  }
    img{
        width:100%;
    }
    p{
        font-size: .7rem;
        text-align:center;
    }
}
@media only screen and (max-width: 992px) {
     .listed-section-container{
        justify-content: space-between !important;
        width: 98vw;
        
        }
        .listed-section-list{
            width: 100%;
        }
    }
    @media screen and (max-width: 778px){
        .listed-section-container{
            flex-direction: column;
        }
        .listed-section-img-container{
            width: 85%;
            img{
                margin-top: 3rem;
            }
            .video-container{
                margin-top: 3rem;
            }
        }
    }
`;
console.log(state.ListedSectionText)
    return (
        
        <StyledListedSection>
            <h4>{state.ListedSectionTitle}</h4>
            <div className="listed-section-container">
                <div className="listed-section-list">
                {state.ListedSectionText.map((text) => <List Text={text.Text} />)}
                </div>
               <div className="listed-section-img-container">{state.Video1 ? <VideoContainer videi={state.Video1}/> : <img src={state.ListedSectionImg} alt=""/> }<p>{state.ListedSectionImgText}</p></div>
            </div>
        </StyledListedSection>
    )
}

const List = ({state, Text}) => {
    return(
        <StyledList>
            <div className="list">
                <img src={LittleLeaf} alt=""/>
                <p>{Text}</p>
            </div>
        </StyledList>
    )
}
const VideoContainer = ({videi}) => {
    return (
        <div className="video-container">
            <Video className='content' video={videi}/>
        </div>
    )
}
const StyledList = styled.div`
flex-direction: column;
.list{
display:flex;
align-items:center;
margin:.5rem;
img{
    width:5%;
    margin-right: .2rem;
}

}

`;